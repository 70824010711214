import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    open: true,
    vip: null,
    rsvp: null,
    verifiedRsvp: null,
    verifiedVip: null,
    registrationType: null,
    eventTiming: null,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    setVip: r => this.setState({ vip: r }),
    setRsvp: r => this.setState({ rsvp: r }),
    setVerified: v => this.setState({ verifiedRsvp: v }),
    setType: t => this.setState({ registrationType: t }),
    setGlobalEventTiming: t => this.setState({ eventTiming: t }),
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
